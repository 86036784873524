<template>
  <div v-loading="$store.state.isLoading">
    <gallery
      :images="carImages"
      :index="galleryIndex"
      @close="galleryIndex = null"
    ></gallery>
    <el-button class="left margin-bottom" @click="$router.push('/')"
      >Zur Übersicht</el-button
    >
    <el-card class="c-car" :body-style="{ padding: '0px' }">
      <el-row class="c-car__uppersection">
        <el-col :xs="24" :sm="12" :md="10">
          <!-- <img
                        class="c-car__img"
                        :src="carImages[0]"
                        alt="xxxxxxxxxxxxxxx"
                    /> -->
          <el-carousel
            :autoplay="false"
            height="355px"
            arrow="always"
            trigger="click"
          >
            <el-carousel-item v-for="(car, index) in carImages" :key="index">
              <div
                :style="'background-image: url(' + carImages[index] + ')'"
                @click="galleryIndex = index"
                class="c-car__imgthumb"
              ></div>
            </el-carousel-item>
          </el-carousel>
        </el-col>
        <div class="print text-center">
          <img height="300" :src="carImages[0]" />
          <br />
          <template v-for="(carImage, index) in carImages">
            <img v-if="index !== 0" width="130" :src="carImage" :key="carImage" />
          </template>
        </div>
        <el-col :xs="24" :sm="12" :md="14">
          <div class="c-car__allgemeine-infos">
            <h1>
              <template v-if="car['Name Marke'] != 'Fremdmarke'">{{ car["Name Marke"] }}</template>
              {{ car["Name Modell Type"] }}
            </h1>
            <br />

            <div class="c-car__price">
              Verkaufspreis
              {{ this.$options.filters.currency(car["Preis"]) }}
            </div>
            <b>Erstzulassung</b>
            {{ formatDate(car["Erstzulassung"]) }}<br />

            <b>Kilometerstand</b> {{ car["KM"] | dot }} km<br />

            <br /><br />
            <el-button @click="scrollToAnfrage()">Anfragen</el-button>
          </div>
        </el-col>
      </el-row>

      <el-row class="c-car__highlights">
        <el-col :xs="12" :sm="8" :md="6">
          <div class="c-car__highlighticon">
            <span class="icon-icon_farbe"></span>
          </div>

          <span class="c-car__highlightlabel">Farbe</span>
          <div class="c-car__highlightvalue">
            {{ car["Außenfarbe-ID"] || "k.A." }}
          </div>
        </el-col>

        <el-col :xs="12" :sm="8" :md="6">
          <div class="c-car__highlighticon">
            <span class="icon-icon_kraftstoff"></span>
          </div>

          <span class="c-car__highlightlabel">Kraftstoff</span>
          <div class="c-car__highlightvalue">
            {{ car["Kraftstoff-ID"] || "k.A." }}
          </div>
        </el-col>

        <el-col :xs="12" :sm="8" :md="6">
          <div class="c-car__highlighticon">
            <span class="icon-icon_hubraum"></span>
          </div>

          <span class="c-car__highlightlabel">Hubraum</span>
          <div class="c-car__highlightvalue">
            {{ car["Hubraum"] || "k.A." }} ccm
          </div>
        </el-col>

        <el-col :xs="12" :sm="8" :md="6">
          <div class="c-car__highlighticon">
            <span class="icon-icon_leistung"></span>
          </div>

          <span class="c-car__highlightlabel">Leistung</span>
          <div class="c-car__highlightvalue">
            {{ car["KW"] || "k.A." }} kW / {{ car["KW"] | ps }} PS
          </div>
        </el-col>

        <el-col :xs="12" :sm="8" :md="6">
          <div class="c-car__highlighticon">
            <span class="icon-icon_automatik"></span>
          </div>

          <span class="c-car__highlightlabel">Getriebeart</span>
          <div class="c-car__highlightvalue">
            {{ car["Getriebeart-ID"] || "k.A." }}
          </div>
        </el-col>

        <!-- <el-col :xs="12" :sm="8" :md="6">
                    <div class="c-car__highlighticon">
                        <span class="icon-icon_manuel"></span>
                    </div>

                    <span class="c-car__highlightlabel">Gänge</span>
                    <div class="c-car__highlightvalue">
                        {{ car["Gänge"] || "k.A." }}
                    </div>
                </el-col>

                <el-col :xs="12" :sm="8" :md="6" v-if="car['Sitze'] !== '0'">
                    <div class="c-car__highlighticon">
                        <span class="icon-icon_sitze"></span>
                    </div>

                    <span class="c-car__highlightlabel">Sitze</span>
                    <div class="c-car__highlightvalue">
                        {{ car["Sitze"] || "k.A." }}
                    </div>
                </el-col>

                <el-col :xs="12" :sm="8" :md="6">
                    <div class="c-car__highlighticon">
                        <span class="icon-icon_polsterung-art"></span>
                    </div>

                    <span class="c-car__highlightlabel">Polsterung</span>
                    <div class="c-car__highlightvalue">
                        {{ car["Polsterung-Art-ID"] || "k.A." }},
                        {{ car["Polsterung-Farbe-ID"] || "k.A." }}
                    </div>
                </el-col> -->

        <!-- <el-col :xs="12" :sm="8" :md="6">
                    <div class="c-car__highlighticon">
                        <span class="icon-icon_polsterung-farbe"></span>
                    </div>

                    <span class="c-car__highlightlabel">Polsterung-Farbe</span>
                    <div class="c-car__highlightvalue">
                        {{ car["Polsterung-Farbe-ID"] || "k.A." }}
                    </div>
                </el-col> -->

        <!-- <el-col :xs="12" :sm="8" :md="6">
                    <div class="c-car__highlighticon">
                        <span class="icon-icon_emission"></span>
                    </div>

                    <span class="c-car__highlightlabel">Emission (CO2)</span>
                    <div class="c-car__highlightvalue">
                        {{ car["Emission (CO2)"] || "k.A." }}
                    </div>
                </el-col>

                <el-col :xs="12" :sm="8" :md="6">
                    <div class="c-car__highlighticon">
                        <span class="icon-icon_verbrauch"></span>
                    </div>

                    <span class="c-car__highlightlabel">Verbrauch l/100km</span>
                    <div class="c-car__highlightvalue">
                        {{ car["Verbrauch l/100km"] || "k.A." }}
                    </div>
                </el-col> -->
      </el-row>

      <el-row class="c-car__lowersection" :gutter="20">
        <!-- <el-col :xs="24" :sm="24" :md="24"> -->
        <!-- <h2>Fahrzeugdaten - Übersicht</h2> -->
        <!-- </el-col> -->
        <el-col :xs="24" :sm="12" :md="12">
          <h2>Fahrzeugdaten</h2>
          <br />
          <!-- <h3>Fahrzeugdaten - Übersicht</h3> -->
          <!-- <b>Händler</b> {{ car["Händler-ID"] }}<br /> -->
          <!-- <b>Fahrzeug</b> {{ car["Fahrzeug-ID"] }}<br /> -->

          <!-- <b>Fahrzeugtyp</b> {{ car["Fahrzeugtyp-ID"] }}<br /> -->
          <!-- <b>Marken</b> {{ car["Marken-ID"] }}<br /> -->
          <!-- <b>Name Marke</b> {{ car["Name Marke"] }}<br /> -->
          <!-- <b>Name Modell Type</b> {{ car["Name Modell Type"] }}<br /> -->
          <!-- <b>Aufbauart</b> {{ car["Aufbauart-ID"] }}<br /> -->
          <!-- <b>Getriebeart</b> {{ car["Getriebeart-ID"] }}<br /> -->
          <!-- <b>Gänge</b> {{ car["Gänge"] }}<br /> -->
          <!-- <b>Antrieb</b> {{ car["Antrieb"] }}<br /> -->
          <!-- <b>Hubraum</b> {{ car["Hubraum"] }}<br /> -->
          <!-- <b>Leistung</b> {{ car["KW"] }} kW<br /> -->
          <!-- <b>Kraftstoff</b> {{ car["Kraftstoff-ID"] }}<br /> -->
          <b>Türen</b> {{ car["Türen"] }}<br />
          <!-- <b>Sitze</b> {{ car["Sitze"] }}<br /> -->
          <!-- <b>Gesamtgewicht</b> {{ car["Gesamtgewicht"] }} kg<br /> -->
          <!-- <b>Kofferraumvolumen</b> {{ car["Kofferraumvolumen"] -->
          <!-- }}<br /> -->
          <!-- <b>Erstzulassung</b> {{ car["Erstzulassung"] }}<br /> -->
          <!-- <b>Preis</b> {{ car["Preis"] }}<br /> -->
          <!-- <b>KM</b> {{ car["KM"] }}<br /> -->
          <!-- <b>Außenfarbe</b> {{ car["Außenfarbe-ID"] }}<br /> -->

          <b>Farbbezeichnung</b> {{ car["Farbbezeichnung"] }}<br />
          <b>Metallic-Lackierung</b> {{ car["Metallic-Lackierung"] }}<br />
          <!-- <b>Polsterung-Art</b> {{ car["Polsterung-Art-ID"] }}<br />
                    <b>Polsterung-Farbe</b> {{ car["Polsterung-Farbe-ID"]
                    }}<br /> -->
          <!-- <b>Garantie</b> {{ car["Garantie"] }}<br /> -->
          <!-- <b>Gewährleistung</b> {{ car["Gewährleistung"] }}<br /> -->
          <!-- <b>MwSt. ausweisbar</b> {{ car["MwSt. ausweisbar"] }}<br /> -->
          <b>Serviceheft</b> {{ car["Serviceheft"] }}<br />
          <!-- <b>Leasingmöglichkeit</b> {{ car["Leasingmöglichkeit"] -->
          <!-- }}<br /> -->
          <!-- <b>NoVA</b> {{ car["NoVA"] }}<br /> -->
          <!-- <b>Abgasnorm</b> {{ car["Abgasnorm"] }}<br /> -->
          <!-- <b>Emission (CO2)</b> {{ car["Emission (CO2)"] }}<br />
                    <b>Verbrauch l/100km</b> {{ car["Verbrauch l/100km"]
                    }}<br /> -->
          <!-- <b>Extra 1</b> {{ car["Extra 1"] }}<br /> -->
          <!-- <b>Extra 2</b> {{ car["Extra 2"] }}<br /> -->
          <!-- <b>Bild</b> {{ car["Bild"] }}<br /> -->
          <!-- <b>Ausstattungen-IDs</b> {{ car["Ausstattungen-IDs"]
                    }}<br /> -->
          <b>Zusatzbeschreibung</b>
          <div v-html='car["Zusatzbeschreibung"]'></div>
          <br /><br />
          <b>Eurotax-Code</b> {{ car["Eurotax-Code"] }}<br />
          <b>Wagen-Nr.</b> {{ car["Wagen-Nr."] }}<br />
        </el-col>
        <el-col :xs="24" :sm="12" :md="12">
          <h2>Ausstattung</h2>
          <br />
          <ul>
            <li v-for="(equipment, index) in equipments" :key="index">
              {{ equipment }}
            </li>
          </ul>
        </el-col>
        <el-col id="anfrage">
          <h3 style="margin-top: 50px;" class="text-center">
            Anfrage
          </h3>
          <br />
          <contact-form fromComponent="fahrzeug" :car="car"></contact-form>
        </el-col>
      </el-row>

      <!-- <img
                class="c-car__img"
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn%3AANd9GcQYVnBtJpSfCyObD5pAhvO6Cjt0AzX2BNWIxWKwebCjYhe92Vik"
                alt="xxxxxxxxxxxxxxx"
            /> -->
      <!-- <h1>{{ car["Name Marke"] }} {{ car["Name Modell Type"] }}</h1> -->

      <!-- <h3>Fahrzeugbeschreibung</h3>
            <b>xxxxxx</b> {{ car["xxxxxxxx"] }}<br />
            <b>xxxxxx</b> {{ car["xxxxxxxx"] }}<br />
            <b>xxxxxx</b> {{ car["xxxxxxxx"] }}<br />
            <b>xxxxxx</b> {{ car["xxxxxxxx"] }}<br />

            <h3>Ausstattung</h3>
            <b>xxxxxx</b> <br /> -->
    </el-card>

    <!-- <h1>{{ car["Name Marke"] }} {{ car["Name Modell Type"] }}</h1>
        <p>
            <el-button @click="$router.push('/')"
                >Zurück zur Übersicht</el-button
            >
        </p> -->
    <!-- <pre>{{ JSON.stringify(car, null, 4) }}</pre> -->
  </div>
</template>

<script>
// import { checkImageExists } from "@/utils/imagecheck";
import VueGallery from "vue-gallery";
import { DateFormatter } from "@/utils/date";
import ContactForm from "@/components/ContactForm";

export default {
  components: {
    gallery: VueGallery,
    ContactForm,
  },
  props: {
    id: {
      required: true,
      type: String,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.$store.dispatch("cars/getCar", this.id).then(() => {
      // this.fillCarImages();
    });
  },
  computed: {
    car() {
      let result = {};
      if (this.$store.state.cars.all[this.id]) {
        result = this.$store.state.cars.all[this.id];
      }
      return result;
    },
    carImages() {
      let result = [];
      if (Array.isArray(this.car.Bild)) {
        for (let image of this.car.Bild) {
          result.push(this.imageUrl + image);
        }
      }
      return result;
    },
    equipments() {
      let result = [];
      if (this.car["Ausstattungen-IDs"]) {
        result = this.car["Ausstattungen-IDs"].split("|");
      }
      // let x = (result) => result.filter((v,i) => result.indexOf(v) === i)
      let unique = [...new Set(result)]; //remove duplicates
      // console.log(result);
      return unique;
    },
  },
  methods: {
    formatDate(dateString) {
      if (dateString == null) {
        return "k.A.";
      }
      return DateFormatter.formatDate(new Date(dateString), "MM.YYYY");
    },
    scrollToAnfrage() {
      document.getElementById("anfrage").scrollIntoView();
    },
    // fillCarImages() {
    //     this.carImages = [];
    //     for (var imgCounter = 1; imgCounter <= 25; imgCounter++) {
    //         checkImageExists(
    //             process.env.VUE_APP_IMAGEURL +
    //                 this.car["Fahrzeug-ID"] +
    //                 "_" +
    //                 imgCounter +
    //                 ".jpg",
    //             imgUrl => {
    //                 if (imgUrl) {
    //                     this.carImages.push(imgUrl);
    //                     this.carImages.sort();
    //                 }
    //             }
    //         );
    //     }
    // }
  },
  data() {
    return {
      // carImages: [],
      galleryIndex: null,
      imageUrl: process.env.VUE_APP_IMAGEURL,
    };
  },
};
</script>

<style lang="scss">
.print {
  display: none;
}
@media print {
  .print {
    display: block;
  }
  .c-car__highlights {
    .el-col-24 {
      width: 33.33%;
    }
  }
  .c-car__lowersection {
    page-break-before: always;
    .el-col-24 {
      width: 50%;
    }
  }
  #anfrage,
  .el-button, .el-carousel, nav, .left-col, #headline, footer {
    display: none;
  }
}

.c-car {
  overflow: hidden;
  &__img {
    width: 100%;
  }
  &__imgthumb {
    height: 100%;
    width: 100%;
    background-size: cover;
    background-position: center center;
  }
  &__allgemeine-infos {
    padding: 20px;
  }

  &__uppersection {
    border-bottom: solid 1px $gangl-grey;
  }
  &__price {
    font-size: x-large;
    margin-bottom: 20px;
  }
  &__highlights {
    padding: 20px;
  }
  &__highlighticon {
    font-size: xxx-large;
    float: left;
    margin-right: 10px;
    color: #9d9d9c;

    span {
      float: left;
    }

    @media screen and (max-width: $breakpoint-sm) {
      font-size: xx-large;
    }
  }
  &__highlightlabel {
    font-size: small;
    @media screen and (max-width: $breakpoint-sm) {
      font-size: xx-small;
    }
  }
  &__highlightvalue {
    font-size: large;
    // font-weight: bold;
    margin-bottom: 20px;
    @media screen and (max-width: $breakpoint-sm) {
      font-size: small;
    }
  }
  &__lowersection {
    padding: 20px;
    ul {
      margin: 0;
      padding-left: 16px;
    }
  }

  .el-carousel__item {
    text-align: center;
  }
}

.el-carousel__indicators {
  .el-carousel__button {
    width: 10px;
    height: 10px;
    background: none;
    border-radius: 20px;
    opacity: 1;
    border: solid 1px #fff;
  }
  .el-carousel__indicator {
    vertical-align: middle;

    &.is-active button {
      position: relative;
      border: none;

      &:before {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        display: block;
        width: 5px;
        height: 5px;
        background: #fff;
        border-radius: 20px;
      }
    }
  }
}
</style>
